
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { BAIconsMixin, AuthMixin, MyCoachMixin, MyTeamsMixin, VuetifyMixin, ShowcaseEventMixin, AppHostnameMixin} from '@/mixins';
import SideNavSectionTitle from './SideNavSectionTitle.vue';
import BestAthletesLogo from '../ui/BestAthletesLogo.vue';
import CircledAvatar from '../ui/CircledAvatar.vue';
import { TeamModel } from '@/models/team';
import * as Routes from '@/../types/constants/web_client_user.routes';

@Component({
	components: { BestAthletesLogo, CircledAvatar, SideNavSectionTitle },
})
export default class CoachSideNav extends Mixins(BAIconsMixin, AuthMixin, MyTeamsMixin, VuetifyMixin, MyCoachMixin, ShowcaseEventMixin, AppHostnameMixin){
	@Prop({ default: true, type: Boolean }) value: boolean;
	mini = false;

	get CreateTeamRoutes(): string{
		return Routes.TeamCreate
	}
	get TeamDashboardRoute(): string{
		return Routes.TeamDashboard;
	}
	get CentralScoutingRoute(): string{
		return Routes.CoachCentralScoutingDashboard;
	}
	get ConnectingRoute(): string{
		return Routes.CoachConnectWithAthletes;
	}
	get ScoutingReportsRoute(): string{
		return Routes.CoachScoutingSummary;
	}
	get GameReportsRoute(): string {
		return Routes.CoachGameReportsSummary;
	}

	CoachProfileRoute(CoachId: string){
		this.$router.push({ name: 'CoachProfile', params: { coachId: CoachId} })
	}

	/**
	* The router's authGuard prevents the app from loading before auth0 is ready but the SideNav is outside of the app's router-view
	* So we watch the auth instance's user property and do some setup once it stops being null.
	*/
	userProfileUnwatch: () => void;
	userProfileReady(): void{
		if(this.userProfileUnwatch) this.userProfileUnwatch();
		this.initMyTeams();
	}
	created(): void{
		this.userProfileUnwatch = this.$auth.$watch("$auth.user", user => {
			if(user !== null){
				this.userProfileReady();
			}
		});
	}

	expanded: boolean = false;
	numToShow = this.IsMobile? 1 : 2;

	get TeamsToShow(): TeamModel[] {
		return this.ShowAllTeams? this.MyTeams : this.MyTeams.slice(0, this.numToShow);
	}

	get ShowAllTeams(): boolean {
		return this.NumTeams <= this.numToShow || this.expanded;
	}

	get ShowButtonMore(): boolean {
		return this.MyTeams.length > this.numToShow && !this.expanded;
	}

	get ShowButtonLess(): boolean {
		return this.MyTeams.length > this.numToShow && this.expanded;
	}
}
